/* ---------------------------------- *\
	General
\* ---------------------------------- */

html {
	box-sizing: border-box;
}

body {
    background-color: $white;
}

*,
*:before,
*:after { /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
	box-sizing: inherit;
}
