/* ---------------------------------- *\
    Core / Fonts
\* ---------------------------------- */

:global(.icon) {
    display: inline;
    align-items: center;

    font-family: 'icon-font' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: inherit;

    color: inherit;

    /* Better Font Rendering */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
