@charset "UTF-8";
/* ---------------------------------- *\
    Organisms / Header
\* ---------------------------------- */
/* -------------------------------------- *\
    Helpers
\* -------------------------------------- */
/* --- Mixins --- */
/* --- Variables --- */
/* ---------------------------------- *\
    Typography
\* ---------------------------------- */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Khula", Arial, sans-serif;
  color: #212121; }

h1,
h2,
h3,
h4,
h5 {
  margin: 10px 0;
  clear: both;
  font-weight: 700;
  word-wrap: break-word; }
  h1 span,
  h2 span,
  h3 span,
  h4 span,
  h5 span {
    font-weight: 300; }
  @media (min-width: 992px) {
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin: 20px 0; } }

h1, .whale {
  font-size: 30px;
  line-height: 38px; }
  @media (min-width: 992px) {
    h1, .whale {
      font-size: 38px;
      line-height: 46px; } }
  @media (min-width: 1440px) {
    h1, .whale {
      font-size: 50px;
      line-height: 58px; } }

h2, .elephant {
  font-size: 26px;
  line-height: 34px;
  /*
	@include bp(lg) {
		font-size: 32px;
		line-height: 40px;
	}
	*/ }
  @media (min-width: 1440px) {
    h2, .elephant {
      font-size: 42px;
      line-height: 50px; } }

h3, .horse {
  font-size: 22px;
  line-height: 30px; }
  @media (min-width: 1440px) {
    h3, .horse {
      font-size: 36px;
      line-height: 44px; } }

h4, .lion {
  font-size: 18px;
  line-height: 26px; }
  @media (min-width: 1440px) {
    h4, .lion {
      font-size: 22px;
      line-height: 30px; } }

.wheat {
  font-size: 18px;
  line-height: 26px; }
  @media (min-width: 1440px) {
    .wheat {
      font-size: 22px;
      line-height: 30px; } }

p, .oat, .navigation .overlay__group a {
  margin: 10px 0;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400; }
  @media (min-width: 1440px) {
    p, .oat, .navigation .overlay__group a {
      margin: 20px 0;
      font-size: 18px;
      line-height: 28px; } }
  p + h1,
  p + h2,
  p + h3,
  p + h4,
  p + h5, .oat + h1, .navigation .overlay__group a + h1,
  .oat + h2,
  .navigation .overlay__group a + h2,
  .oat + h3,
  .navigation .overlay__group a + h3,
  .oat + h4,
  .navigation .overlay__group a + h4,
  .oat + h5,
  .navigation .overlay__group a + h5 {
    margin-top: 30px; }
    @media (min-width: 1440px) {
      p + h1,
      p + h2,
      p + h3,
      p + h4,
      p + h5, .oat + h1, .navigation .overlay__group a + h1,
      .oat + h2,
      .navigation .overlay__group a + h2,
      .oat + h3,
      .navigation .overlay__group a + h3,
      .oat + h4,
      .navigation .overlay__group a + h4,
      .oat + h5,
      .navigation .overlay__group a + h5 {
        margin-top: 60px; } }

small, .barley,
:global(.barley) {
  padding: 4px 0 0;
  font-size: 14px;
  line-height: 18px; }
  @media (min-width: 1440px) {
    small, .barley,
    :global(.barley) {
      font-size: 15px;
      line-height: 19px; } }

.header {
  position: relative;
  z-index: 99;
  height: 120px; }
  .header .header__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1440px;
    width: 100%;
    height: inherit;
    padding-left: 40px;
    padding-right: 40px;
    margin: 0 auto; }
    @media (min-width: 1200px) {
      .header .header__inner {
        padding-left: 0;
        padding-right: 0; } }

.navigation {
  position: relative;
  z-index: 1; }
  .navigation .overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    opacity: 0;
    pointer-events: none; }
  .navigation .overlay--loaded {
    transition: 300ms ease-in; }
  .navigation .overlay--visible {
    opacity: 1;
    transition: 300ms ease-out;
    pointer-events: all; }
  .navigation .overlay__inner {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    max-width: 1440px;
    width: 100%;
    height: 100%;
    padding: 120px 40px;
    margin: 0 auto; }
    @media (min-width: 1200px) {
      .navigation .overlay__inner {
        padding: 180px 0px; } }
  .navigation .overlay__secondary {
    display: none;
    grid-area: 2 / 1 / 2 / span 12;
    margin: 40px 0; }
    @media (min-width: 1440px) {
      .navigation .overlay__secondary {
        display: block;
        grid-area: 1 / 1 / 1 / 3;
        margin: 0; } }
    .navigation .overlay__secondary ul {
      margin: 10px 0; }
  .navigation .overlay__primary {
    display: flex;
    grid-area: 1 / 1 / 1 / span 12;
    margin: 40px 0; }
    @media (min-width: 1440px) {
      .navigation .overlay__primary {
        grid-area: 1 / 4 / 1 / span 4;
        margin: 0; } }
  .navigation .overlay__content {
    display: none;
    grid-area: 1 / 1 / 1 / span 12;
    margin: 40px 0; }
    @media (min-width: 1440px) {
      .navigation .overlay__content {
        display: block;
        grid-area: 1 / 8 / 1 / span 4;
        margin: 10px 0; } }
  .navigation .overlay__group {
    margin-bottom: 60px; }
    .navigation .overlay__group small {
      display: block;
      color: rgba(33, 33, 33, 0.5); }
    .navigation .overlay__group a {
      margin-right: 20px;
      color: #212121; }

.burgermenu {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 300ms ease; }
  .burgermenu button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 40px;
    height: 40px;
    padding: 0;
    border: 0;
    outline: 0;
    background: none;
    cursor: pointer;
    user-select: none; }
  .burgermenu span {
    display: block;
    width: 24px;
    height: 2px;
    background-color: #212121;
    border-radius: 1px;
    transition: 300ms ease;
    position: relative; }
    .burgermenu span:before, .burgermenu span:after {
      display: block;
      width: 24px;
      height: 2px;
      background-color: #212121;
      border-radius: 1px;
      transition: 300ms ease;
      content: "";
      position: absolute; }
    .burgermenu span:before {
      transform: translateY(-6px); }
    .burgermenu span:after {
      width: 16px;
      margin-left: 8px;
      transform: translateY(6px); }
  .burgermenu:hover span:before {
    transform: translateY(-9px); }
  .burgermenu:hover span:after {
    transform: translateY(9px); }

.burgermenu--toggled {
  transform: rotate(90deg); }
  .burgermenu--toggled span {
    background-color: transparent; }
    .burgermenu--toggled span:before {
      transform: rotate(-45deg); }
    .burgermenu--toggled span:after {
      width: 24px;
      margin: 0;
      transform: rotate(45deg); }
  .burgermenu--toggled:hover {
    transform: rotate(0deg); }
    .burgermenu--toggled:hover span:before {
      transform: rotate(-45deg); }
    .burgermenu--toggled:hover span:after {
      transform: rotate(45deg); }

:global(.js-menu-toggle) {
  height: 100vh;
  overflow-y: hidden; }
