@charset "UTF-8";
/* ---------------------------------- *\
    Main app styling
\* ---------------------------------- */
/* Core */
/* -------------------------------------- *\
    Helpers
\* -------------------------------------- */
/* --- Mixins --- */
/* --- Variables --- */
/* ---------------------------------- *\
    Core / Fonts
\* ---------------------------------- */
:global(.icon) {
  display: inline;
  align-items: center;
  font-family: 'icon-font' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: inherit;
  color: inherit;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* ---------------------------------- *\
    Typography
\* ---------------------------------- */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Khula", Arial, sans-serif;
  color: #212121; }

h1,
h2,
h3,
h4,
h5 {
  margin: 10px 0;
  clear: both;
  font-weight: 700;
  word-wrap: break-word; }
  h1 span,
  h2 span,
  h3 span,
  h4 span,
  h5 span {
    font-weight: 300; }
  @media (min-width: 992px) {
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin: 20px 0; } }

h1, .whale {
  font-size: 30px;
  line-height: 38px; }
  @media (min-width: 992px) {
    h1, .whale {
      font-size: 38px;
      line-height: 46px; } }
  @media (min-width: 1440px) {
    h1, .whale {
      font-size: 50px;
      line-height: 58px; } }

h2, .elephant {
  font-size: 26px;
  line-height: 34px;
  /*
	@include bp(lg) {
		font-size: 32px;
		line-height: 40px;
	}
	*/ }
  @media (min-width: 1440px) {
    h2, .elephant {
      font-size: 42px;
      line-height: 50px; } }

h3, .horse {
  font-size: 22px;
  line-height: 30px; }
  @media (min-width: 1440px) {
    h3, .horse {
      font-size: 36px;
      line-height: 44px; } }

h4, .lion {
  font-size: 18px;
  line-height: 26px; }
  @media (min-width: 1440px) {
    h4, .lion {
      font-size: 22px;
      line-height: 30px; } }

.wheat {
  font-size: 18px;
  line-height: 26px; }
  @media (min-width: 1440px) {
    .wheat {
      font-size: 22px;
      line-height: 30px; } }

p, .oat, a, ul {
  margin: 10px 0;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400; }
  @media (min-width: 1440px) {
    p, .oat, a, ul {
      margin: 20px 0;
      font-size: 18px;
      line-height: 28px; } }
  p + h1,
  p + h2,
  p + h3,
  p + h4,
  p + h5, .oat + h1, a + h1, ul + h1,
  .oat + h2,
  a + h2,
  ul + h2,
  .oat + h3,
  a + h3,
  ul + h3,
  .oat + h4,
  a + h4,
  ul + h4,
  .oat + h5,
  a + h5,
  ul + h5 {
    margin-top: 30px; }
    @media (min-width: 1440px) {
      p + h1,
      p + h2,
      p + h3,
      p + h4,
      p + h5, .oat + h1, a + h1, ul + h1,
      .oat + h2,
      a + h2,
      ul + h2,
      .oat + h3,
      a + h3,
      ul + h3,
      .oat + h4,
      a + h4,
      ul + h4,
      .oat + h5,
      a + h5,
      ul + h5 {
        margin-top: 60px; } }

small, .barley, button,
:global(.barley, button) {
  padding: 4px 0 0;
  font-size: 14px;
  line-height: 18px; }
  @media (min-width: 1440px) {
    small, .barley, button,
    :global(.barley, button) {
      font-size: 15px;
      line-height: 19px; } }

/* ---------------------------------- *\
	General
\* ---------------------------------- */
html {
  box-sizing: border-box; }

body {
  background-color: #ffffff; }

*,
*:before,
*:after {
  /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
  box-sizing: inherit; }

/* ---------------------------------- *\
    Elements
\* ---------------------------------- */
img {
  max-width: 100%; }

a {
  text-decoration: none;
  color: #2E7AD8; }
  a:hover {
    text-decoration: underline; }

strong {
  font-weight: 700;
  color: #515151; }

button {
  background: none; }

ul {
  padding-left: 30px;
  list-style: disc; }

ul li {
  margin: 10px 0; }
