/* ---------------------------------- *\
    Organisms / Block
\* ---------------------------------- */

@import 'styles/helpers';

.block {
    margin: 40px 30px;

    @include bp(lg) {
        margin: 80px 0;
    }

    @include bp(hg) {
        margin: 120px 0;
    }

    &.block--dark {
        padding: 60px 30px;
        margin: 0;

        border-top: 1px solid $border-color;

        @include bp(lg) {
            padding: 80px 0;
        }

        @include bp(hg) {
            padding: 120px 0;
        }
    }

    .block__inner {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        align-items: center;
        max-width: $site-width;
        width: 100%;
        margin: 0 auto;
    }

    &.block--left .block__inner {
        justify-content: flex-start;
    }
    &.block--right .block__inner {
        justify-content: flex-end;
    }
    &.block--top .block__inner {
        align-items: flex-start;
    }
    &.block--bottom .block__inner {
        align-items: flex-end;
    }

    .block__header {
        grid-area: auto / 1 / auto / span 12;
        grid-row: auto;

        @include bp(hg) {
            grid-area: auto / 3 / auto / span 5;
            grid-row: auto;
        }
    }

    .block__content {
        grid-area: auto / 1 / auto / span 12;

        @include bp(hg) {
            grid-area: auto / 4 / auto / span 6;
        }
    }

    .block__column:nth-child(1) {
        grid-area: auto / 1 / auto / span 12;

        @include bp(hg) {
            grid-area: auto / 3 / auto / span 4;
        }
    }
    .block__column:nth-child(2) {
        grid-area: auto / 1 / auto / span 12;

        @include bp(hg) {
            grid-area: auto / 7 / auto / span 4;
        }
    }
}
