/* ---------------------------------- *\
    Organisms / Pillars
\* ---------------------------------- */

@import 'styles/helpers';

.pillars {
    margin: 60px 30px;

    @include bp(lg) {
        margin: 80px 0;
    }

    @include bp(hg) {
        margin: 120px 0;
    }

    &.pillars--dark {
        padding: 60px 30px;
        margin: 0;

        border-top: 1px solid $border-color;

        @include bp(lg) {
            padding: 80px 0;
        }

        @include bp(hg) {
            padding: 120px 0;
        }
    }

    .pillars__inner {
        position: relative;

        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: auto;
        max-width: $site-width;
        width: 100%;
        margin: 0 auto;

        &:before {
            content: "";

            position: absolute;
            top: 5%;
            right: 15%;

            display: none;
            width: 50%;
            height: 100%;

            background-color: rgba($blue, 0.05);
            border-radius: 80% 20% 60% 30% / 40% 40% 60% 60%;

            @include bp(hg) {
                display: block;
            }
        }
    }

    .pillars__header {
        grid-column: 1 / span 12;
        margin-bottom: 40px;

        @include bp(hg) {
            grid-column: 1 / span 4;
            grid-row: 1;
            margin-bottom: 80px;
        }

        h2 {
            margin: 0;
        }
    }

    .pillars__content {
        display: grid;
        grid-column: 1 / span 12;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 40px;
        width: 100%;

        @include bp(hg) {
            grid-row: 1;
            grid-template-columns: repeat(12, 1fr);
            grid-template-rows: repeat(5, 80px);
        }
    }

    .pillar {
        position: relative;
        z-index: 9;
        padding: 20px;

        background-color: $white;
        box-shadow: $shadow-big;

        transition: transform $speed $animation,
            z-index $speed step-end;

        @include bp(hg) {
            padding: 40px;
        }

        &:hover {
            z-index: 10;

            transform: scale(1.05);
            transition: transform $speed $animation,
                z-index $speed step-start;
        }
    }

    .pillar:nth-child(1) {
        grid-area: 1 / 1 / span 1 / span 1;

        @include bp(hg) {
            grid-area: 3 / 4 / span 3 / span 3;
        }
    }
    .pillar:nth-child(2) {
        grid-area: 2 / 1 / span 1 / span 1;

        color: $white;
        background-color: $blue;

        @include bp(hg) {
            grid-area: 1 / 7 / span 3 / span 3;
        }
    }
    .pillar:nth-child(3) {
        grid-area: 3 / 1 / span 1 / span 1;

        @include bp(hg) {
            grid-area: 2 / 10 / span 3 / span 3;
        }
    }
}
