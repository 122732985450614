/* ---------------------------------- *\
    Organisms / Footer
\* ---------------------------------- */

@import 'styles/helpers';

.footer {
    margin-left: 30px;
    margin-right: 30px;

    border-top: 1px solid rgba($black, 0.1);

    @include bp(md) {
        padding-right: 0;
        padding-left: 0;
    }

    .footer__inner {
        max-width: $site-width;
        width: 100%;
        margin: 0 auto;

        @include bp(md) {
            padding-left: 40px;
            padding-right: 40px;
        }
        @include bp(hg) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .footer__content {
        display: flex;
        flex-flow: row wrap;
        padding: 40px 0;

        @include bp(lg) {
            padding: 120px 0;
        }
    }
    .footer__column {
        flex: 1;
        width: 50%;

        @include bp(lg) {
            flex: 0;
            min-width: 180px;
            width: auto;
        }
    }
    .footer__column--main {
        flex: 1 0 auto;
        width: 100%;
        margin-bottom: 30px;

        @include bp(md) {
            flex: 1;
            margin: 0;
        }

        h2 {
            margin-bottom: 20px;
        }

        button, a {
            margin: 0;
        }
    }

    .footer__copyright {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;

        border-top: 1px solid rgba($black, 0.1);

        @include bp(hg) {
            padding: 40px 0;
        }
    }
}
