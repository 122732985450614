/* ---------------------------------- *\
    Organisms / Hero
\* ---------------------------------- */

@import 'styles/helpers.scss';

.hero {
    position: relative;

    display: flex;
    justify-content: center;
    margin: 30px 0;

    @include bp(hg) {
        margin: 60px 0;
    }

    .hero__bg {
        position: absolute;
        z-index: 1;

        display: grid;
        grid-template: repeat(6, 120px) / repeat(12, 1fr);
        max-width: $site-width;
        width: 100%;
        margin: 40px auto 0;
    }

    .hero__inner {
        position: relative;
        z-index: 11;

        display: grid;
        grid-template-columns: repeat(12, 1fr);
        max-width: $site-width;
        width: 100%;
        margin: 0 auto;

        @include bp(hg) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .hero__header {
        grid-area: auto / 1 / auto / span 12;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        padding-left: 30px;
        padding-right: 30px;
        margin: 0;

        font-weight: $weight-bold;

        @include bp(hg) {
            grid-area: auto / 1 / auto / span 12;
            padding-left: 0;
            padding-right: 0;
        }

        p {
            grid-area: auto / 1 / auto / span 12;

            @include bp(hg) {
                grid-area: auto / 3 / auto / span 5;
            }
        }

        h1 {
            margin-top: 0;
            grid-area: auto / 1 / auto / span 12;

            @include bp(hg) {
                grid-area: auto / 4 / auto / span 6;
            }
        }
    }

    .hero__content {
        grid-area: auto / 1 / auto / span 12;
        padding-left: 30px;
        padding-right: 30px;

        @include bp(hg) {
            grid-area: auto / 4 / auto / span 5;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .hero__image {
        grid-area: auto / 1 / auto / span 12;
        display: flex;
        align-items: center;
        max-height: 600px;
        margin-top: 40px;

        overflow: hidden;

        @include bp(xl) {
            margin-top: 120px;
        }

        img {
            width: 100%;
        }
    }
}
